<template>
  <FullScreenDialog
    v-model="serviceDialog"
    title="Servizi"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in serviceTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <GeneralForm mode="new" @edit-phases="openEditPhases"></GeneralForm>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <PhasesForm generate-id-when-create></PhasesForm>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="$router.push({path: pathToGoBack})"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        :loading="loading"
        @click="submitForm"
      >Salva</v-btn>
    </template>

    <template v-slot:header-actions>
      <StandardDialog 
        v-model="editPhasesDialog" 
        :dialog-height="null" 
        title="Modifica fasi" 
        close-button-text="Chiudi"
        dialog-max-width="700px"
      >
        <PhasesForm 
          v-model="phases"
          @move-phase="movePhase" 
          @update-phase="updatePhase"
          @create-phase="createPhase"
          @remove-phase="removePhase"
        ></PhasesForm>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import GeneralForm from "@/components/areas/registries/services/form/GeneralFormTab.vue"
import PhasesForm from "@/components/areas/registries/services/form/PhasesFormTab.vue"
import StandardDialog from '@/components/common/StandardDialog.vue';
import servicesService from '@/services/services/services.service.js'
import serviceForm from '@/services/services/service.form.js'

export default {
  name: "ServicesRegistryNewForm",
  components: {
    FullScreenDialog,
    GeneralForm,
    PhasesForm,
    StandardDialog
  },
  data: function() {
    return {
      tab: null,
      serviceDialog: this.openDialog,
      serviceTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm",
        },
/*         {
          key: 1,
          title: "Fasi",
          name: "phases",
          component: "PhasesForm"
        } */
      ],
      formValid: false,
      loading: false,
      service: undefined,
      phases: [],
      editPhasesDialog: false,
      originalUrl: window.location.pathname
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    serviceForm.resetService()
    serviceForm.on('update', function(data) {
      self.service = data.service
    })

    serviceForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/registries"
    },
    tabName: {
      type: String,
      default: 'general'
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.serviceTabs.length; i++) {
        if(this.serviceTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      this.loading = true
      servicesService.create(this.service).then((result) => {
        this.loading = false
        this.$router.push({path: this.pathToGoBack})
      })
    },
    openEditPhases() {
      this.editPhasesDialog = true
    },
    movePhase(phase, newPosition) {
      phase.serviceId = this.service.id
      servicePhasesService.move(phase, newPosition).then(() => {
        this.updatePhases()
      })
    },
    updatePhase(phase) {
      phase.serviceId = this.service.id
      servicePhasesService.update(phase).then(() => {
        this.updatePhases()
      })
    },
    createPhase(newPhase) {
      newPhase.serviceId = this.service.id
      servicePhasesService.create(newPhase).then(() => { 
        this.updatePhases()
      })
    },
    removePhase(phase) {
      phase.serviceId = this.service.id
      servicePhasesService.remove(phase).then(() => {
        this.updatePhases()
      })
    },
    updatePhases() {
      serviceForm.updateField('servicePhases', this.phases)
    }
  },
  watch: {
    openDialog(newVal) {
      this.serviceDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.serviceTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
  }
};
</script>